import 'stylesheets/flat_2016/custom/31/index.scss';
import 'javascripts/flat_2016/index';

let partnersHeader = document.querySelector('[data-voc-component="comp-header"]')
let header = document.getElementById("header");
let disclaimer = document.querySelector(".head-disclaimer");
let backdrop = document.querySelector(".backdrop");

let disclaimerHeight = disclaimer ? disclaimer.offsetHeight : 0;
let headersHeight = disclaimerHeight + partnersHeader.offsetHeight + header.offsetHeight;

document.addEventListener("DOMContentLoaded", () => {
  if (window.eventLayer[0].shopname && window.eventLayer[0].shopname.toLowerCase() === "cecotec") {
    const cardCoupons = document.querySelectorAll('.coupons__item');

    for (let i = 0, length = cardCoupons.length; i < length; i++) {
      setTimeout(() => {
        cardCoupons[i].querySelector('.coupon__toggle').click();
      }, 100);
    }
  }

  toggleNav(backdrop);
  scrollToTop();
  expandMobileFooter();
  handleDropdown(headersHeight);
  changeBackdropAndMobNavPosition(headersHeight, backdrop);
});

function toggleNav(backdrop) {
  let header = document.getElementById("voc-header");
  let navToggler = header.querySelector('[data-voc-component="header-menu"]');
  let hiddenNav = document.getElementById("voc-wrapper");
  let socialNetworks = document.getElementById("voc-social");

  let menuShown = false;

  if (header) {
    navToggler.addEventListener("click", () => {
      document.body.classList.toggle("mb-is-show");
      socialNetworks.classList.toggle("voc-social-networks-visible");
      hiddenNav.classList.toggle("voc-wrapper-hidden");

      transformBurger();
      handleBackdrop();
    });
  }

  function transformBurger() {
    if (document.body.classList.contains("mb-is-show")) {
      navToggler.classList.add("voc-header-menu_cross");
      menuShown = false;
      backdrop.classList.add("backdrop--visible");
    } else {
      navToggler.classList.remove("voc-header-menu_cross");
      menuShown = true;
      backdrop.classList.remove("backdrop--visible");
    }
  }

  function handleBackdrop() {
    if (menuShown) {
      backdrop.addEventListener("click", () => {
        hiddenNav.classList.add("voc-wrapper-hidden");
        navToggler.classList.remove("voc-header-menu_cross");
        document.body.classList.remove("mb-is-show");
      });
    }
  }
}


function scrollToTop() {
  let btn = document.querySelector('[data-voc-component="to-top"]');

  btn.addEventListener("click", (ev) => {
    ev.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  })
}

function expandMobileFooter() {
  let btn = document.querySelector('[data-voc-component="footer-arrow-menu"]');
  let navFooter = document.querySelector('[data-voc-component="nav-footer"]');

  btn.addEventListener("click", () => {
    btn.classList.toggle("rotation");
    navFooter.classList.toggle("is-show-blind");
  })
}

function handleDropdown() {
  let shops = document.querySelector(".has-sub-menu__shops");
  let dropdown = document.querySelector(".sub-menu__shops");

  shops.addEventListener("mouseover", () => {
    let left = shops.getBoundingClientRect().left;

    dropdown.style.left = `${left}px`;
  });
}

function changeBackdropAndMobNavPosition(headersHeight) {
  let mobNavToggle = document.getElementById("navbar-toggle");
  let mobNav = document.getElementById("navigation-mobile");

  mobNavToggle.addEventListener("click", ()=> {
    backdrop.style.top = `${headersHeight}px`;
    mobNav.style.top = `${headersHeight}px`;
  })
}
